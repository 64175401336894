<template>
  <div>
    <p class="fz-36 fw-400 mb-5">{{ props.podcastInfo.title || props.podcastInfo.course_title }}</p>
    <p class="fz-22 fw-300 mb-5">
      <span
        class="pointer"
        @click="goToCompanyPage"
      >{{ courseData.company.name }}</span>

      <span class="px-8">&bull;</span>

      <span
        class="pointer"
        @click="goToSeriesPage"
      >{{ streamData.series_title }}</span>
    </p>


    <div class="is-flex ion-align-items-center ion-justify-content-between">
      <p class="fz-14 fw-300">
        <template v-if="formatDate">
          <span>{{ formatDate }} </span>
          <span class="px-8">&bull;</span>
        </template>
        <template v-if="props.podcastInfo.duration">
          <span>{{ Math.round(props.podcastInfo.duration / 60) }} {{ $t('podcast.min') }}</span>
        </template>
      </p>

      <div class="is-flex">
        <ShareButton
          class="mr-3"
          :text="props.podcastInfo.title"
          :course-id="seriesId"
          :stream-id="streamId"
        />
        <IonIcon
          v-if="props.podcastInfo.isSeries"
          class='pointer mr-3'
          :icon="notificationIsOn ? notifications : notificationsOutline"
          @click="notificationIsOn = !notificationIsOn"
        />
        <FavouriteButton
          :course-id="courseId"
          :stream-id="streamId"
        />
      </div>
    </div>

    <div class="fz-18 fw-300 mt-10">
      <span
        v-html="isReadMore ? shortPodcastDescription : description"
        class="description"
      ></span>
      <p
        v-if="textLength > maxLengthText"
        class="ion-text-right pointer"
        @click="isReadMore = !isReadMore"
      >
        {{ readMore }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n/index';
import { useStore } from "vuex";

import { IonIcon } from '@ionic/vue';
import {
  notifications,
  notificationsOutline,
} from 'ionicons/icons';

import ShareButton from '@/components/common/ShareButton.vue';
import FavouriteButton from '@/components/common/FavouriteButton.vue';

const props = defineProps({
  podcastInfo: {
    type: Object,
    required: true,
    default: () => ({}),
  },
});

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();

const streamData = computed(() => store.getters['questionnaires/getStream']);
const courseData = computed(() => store.getters['course/getCourseData']);

const formatDate = computed(() => {
  if (props.podcastInfo.rss_feed_publish_date) {
    const date = new Date(props.podcastInfo.rss_feed_publish_date);
    return new Intl.DateTimeFormat(t('podcast.local'), { dateStyle: 'long' }).format(date);
  } else if (props.podcastInfo.created_at) {
    const date = new Date(props.podcastInfo.created_at);
    return new Intl.DateTimeFormat(t('podcast.local'), { dateStyle: 'long' }).format(date);
  }

  return null;
});

const description = computed(() => props.podcastInfo.course_introduction ??props.podcastInfo.description);
const isReadMore = ref(true);
const maxLengthText = 500;
const readMore = computed(() =>
  isReadMore.value ? t('podcast.readMore') : t('podcast.readLess'),
);
const textLength = computed(() => description.value?.length);
const shortPodcastDescription = computed(() => {
  if (!description.value) return '';

  const isLong = textLength.value > maxLengthText;
  return description.value.substring(0, maxLengthText) + (isLong ? ' ...' : '');
});

const notificationIsOn = ref(props.podcastInfo.notificationIsOn);

const seriesId = computed(() => {
  return route.params.courseId || courseId.value; // BE should add seriesId to the payload
});
const courseId = computed(() => {
  return props.podcastInfo.course_id ? props.podcastInfo.course_id : props.podcastInfo.id;
});
const streamId = computed(() => {
  return props.podcastInfo.streams ? null : props.podcastInfo.id;
});

function goToCompanyPage () {
  router.push(`/company/${courseData.value.company.id}`);
  store.commit('player/setPlayerCollapse', true);
}

function goToSeriesPage () {
  router.push(`/course/${streamData.value.series_id}`);
  store.commit('player/setPlayerCollapse', true);
}
</script>

<style scoped lang="scss">
.img-wrap::part(image) {
  overflow: hidden;
  border-radius: 10px;
  height: calc(100vw - 32px);
  object-fit: cover;
}
.description {
  line-height: 1.5rem;

  :deep(*) {
    line-height: 1.5rem;
  }
}
</style>
