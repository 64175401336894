<template>
  <transition name="slide-player">
    <ion-grid
      v-if="playerActive"
      :style="{ transform: `translate(0, ${translateY}px)` }"
      class="player-wrapper"
      :class="{'transform-up': transformUp}"
    >
      <transition-group
        name="slide-player"
      >
        <ion-row
          v-show="!playerCollapsed"
          :key="1"
          class="player-layout top-safe-space"
        >
          <ion-col
            class="ion-padding"
            size="1"
            @touchstart="handleSwipeStart"
            @touchmove="handleSwiping"
            @touchend="handleSwipeOff"
          >
            <IonIcon
              class="pointer"
              :icon="chevronDown"
              size="large"
              @click="store.commit('player/setPlayerCollapse', true)"
            />
          </ion-col>

          <!-- Swiper -->
          <ion-col
            size="4"
            class="is-flex ion-align-items-center h-100"
          >
            <swiper
              :initial-slide="indexOfCurrentEpisode"
              :direction="'vertical'"
              :effect="'coverflow'"
              :grab-cursor="true"
              :centered-slides="true"
              :slides-per-view="3"
              :coverflow-effect="{
                rotate: 0,
                slideShadows: false,
              }"
              :modules="modules"
              class="mySwiper"
              slide-to-clicked-slide
              @afterInit="swiperInit"
              @slideChange="slideChangeHandler"
            >
              <swiper-slide
                v-for="({ id, banner}) in allEpisodes"
                :key="id"
              >
                <AppImg
                  :img-src="banner"
                  img-alt="banner"
                  class="banner"
                  :class="{'banner--transition': swiperLoaded}"
                  :show-skeleton="false"
                  border-radius="10px"
                />
              </swiper-slide>
            </swiper>
          </ion-col>

          <ion-col
            size="6"
            class="ion-padding h-100vh-minus-audio-player-and-progress"
            style="overflow-y: auto;"
          >
            <PodcastInfo
              :podcast-info="currentEpisode"
              class="ion-padding-horizontal mt-12"
            />

            <AccreditationRequirements class="ion-padding-horizontal mt-12" />
          </ion-col>

          <!-- Player -->
          <div class="audio-wrapper">
            <ExamProgress
              :progress-only="true"
            />

            <AudioPlayer
              ref="player"
              autoplay
              :playlist="playerPlaylist"
              :playlist-start-index="playerPlaylistStartAt"
              :seek-position="currentSeekPosition"
              :course="currentCourse"
              :skip-autoplay-id="skipAutoplayId"
              :progress="progressHistory"
              :scrubbing-disabled="!!currentEpisode.disable_scrubbing"
              @seeked="initTrackingInterval"
              @track-ready="startTracking"
              @duration="streamDuration = $event"
              @autoplay-skipped="store.commit('player/setSkipAutoplayId', null)"
            />
          </div>
        </ion-row>

        <!-- Mini Player-->
        <div
          v-show="playerCollapsed"
          :key="2"
          class="player-fixed pointer"
          :class="{ 'player-fixed-bottom': !hasPaddingBottom }"
          @click="store.commit('player/setPlayerCollapse', false)"
        >
          <MiniPlayer
            :title="currentEpisode.title"
            :subtitle="currentEpisode.description"
            :duration="trackInfo.trackDuration || currentEpisode.duration"
            :current-time="trackInfo.trackCurrentTime"
            :is-playing="trackInfo.trackPlaying"
            :podcast-media="currentEpisode.media"
            :course-media="currentCourse.media"
            :closable="isMiniplayerClosable"
            @close="store.dispatch('player/closePlayer')"
            @toggle-play.stop="player.togglePlay()"
            @pause="player.pause()"
          />
        </div>
      </transition-group>
    </ion-grid>
  </transition>
</template>

<script setup>
import {
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
} from '@ionic/vue';
import { computed, ref, watch} from 'vue';
import { useStore } from "vuex";
import { chevronDown } from 'ionicons/icons';
import debounce from 'lodash/debounce';

import AccreditationRequirements from "@/components/common/AccreditationRequirements";
import AudioPlayer from "@/components/player/AudioPlayer";
import MiniPlayer from "@/components/player/MiniPlayer";
import PodcastInfo from "@/components/common/PodcastInfoNew";
import AppImg from '@/components/common/AppImg.vue';
import ExamProgress from '@/components/accreditation/ExamProgress';

import { Swiper, SwiperSlide } from 'swiper/swiper-vue';
import 'swiper/swiper.css';
import { EffectCoverflow } from 'swiper/modules';
import { useRoute } from 'vue-router';

import useStreamTracking from '@/composables/player/useStreamTracking';
import usePlayerSwipe from '@/composables/player/usePlayerSwipe';
import usePlayerState from '@/composables/player/usePlayerState';

import { BackgroundMode } from '@awesome-cordova-plugins/background-mode';

const modules= [EffectCoverflow];
const store = useStore();
const route = useRoute();
const player = ref(null);
const swiperRef = ref(null);
const swiperLoaded = ref(false);

const {
  progressHistory,
  initTrackingInterval,
  startTracking,
  clearTrackingInterval,
  clearExpiredStorageProgress,
  currentSeekPosition,
} = useStreamTracking();

const {
  transformUp,
  translateY,
  handleSwipeStart,
  handleSwiping,
  handleSwipeOff,
} = usePlayerSwipe();

const {
  playerActive,
  playerCollapsed,
  currentEpisode,
  currentCourse,
  playerPlaylist,
  playerPlaylistStartAt,
  skipAutoplayId,
  trackInfo,
  indexOfCurrentEpisode,
  allEpisodes,
  isMiniplayerClosable,
} = usePlayerState();

watch(playerActive, (newValue) => {
  if (!newValue) {
    clearTrackingInterval();
  }
});

const getPlayerPlayPauseTriggers = computed(() => store.state.player.playerPlayPauseTriggers);

watch(
  () => getPlayerPlayPauseTriggers.value.pause,
  () => {
    player.value.pause();
  },
  { deep: true },
);
watch(
  () => getPlayerPlayPauseTriggers.value.pause,
  () => {
    player.value.play();
  },
  { deep: true },
);

const handleBackgroundMode = debounce(function (isPlaying) {
  const isAndroid = store.state.app.platform === 'android';
  if (!isAndroid) {
    return;
  }
  if (isPlaying) {
    BackgroundMode.enable();
  } else {
    BackgroundMode.disable();
  }

}, 1000);

watch(
  () => trackInfo.value.trackPlaying,
  (isPlaying) => {
    handleBackgroundMode(isPlaying);
  },
);

watch(() => currentEpisode.value.id, () => {
  if (swiperRef.value) {
    swiperRef.value.slideTo(indexOfCurrentEpisode.value);

  }
});

const streamDuration = ref(null);
const hasPaddingBottom = computed(() => {
  return route.name !== 'PodcastSeries'
        && route.name !== 'Contact'
        && route.name !== 'Company'
        && route.name !== 'PublicPodcastSeries'
        && route.name !== 'ContinueWatching';
});

function swiperInit(swiper) {
  swiperRef.value = swiper;

  setTimeout(() => {
    swiperLoaded.value = true;
  }, 1000);
}

function slideChangeHandler (event) {
  const activeIndex = event.activeIndex;

  if (activeIndex === undefined) {
    return;
  }

  if (activeIndex === indexOfCurrentEpisode.value) {
    return;
  }

  const direction = activeIndex > indexOfCurrentEpisode.value ? 1 : -1;

  // let animation finish and then fetch data
  // TODO: find another solution
  setTimeout(() => {
    player.value.skipHandler(direction, true);
  }, 300);
}

clearExpiredStorageProgress();
</script>

<style scoped lang="scss">
  .player-wrapper {
    display: block;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 6;
    pointer-events: none;
    padding: 0;
    color: white;

    * {
      pointer-events: all;
    }

    .player-layout {
      height: 100%;
      width: 100%;
      background-color: rgb(0, 0, 0);
      background-image: linear-gradient(rgba(96, 24, 24, 0.6), rgb(0, 0, 0) 85%);

      .h-100vh-minus-audio-player-and-progress {
        height: calc(100dvh - 235px);
      }

      .audio-wrapper {
        position: fixed;
        padding: 0 32px 16px 32px;
        width: calc(calc(6 / var(--ion-grid-columns, 12))* 100%);
        max-width: calc(calc(6 / var(--ion-grid-columns, 12))* 100%);
        left: calc(calc(5 / var(--ion-grid-columns, 12))* 100%);
        bottom: 0;
      }
    }

    .player-fixed {
      position: fixed;
      bottom: calc(51px + var( --safe-area-inset-bottom)) !important;
      opacity: 1;
      left: 0px;
      display: block;
      width: 100%;
      padding: 0px;

      &-bottom{
        bottom: var( --safe-area-inset-bottom) !important;
      }
    }

    // --- Swiper ---
    .mySwiper {
      width: 80%;
      height: 90%;

      .banner {
        height: auto;
        width: 60%;
        opacity: 0;

        &--transition {
          transition: all 0.3s ease;
        }
      }

      .swiper-slide {
        display: flex;
        justify-content: center;

        &-next, &-prev {
          .banner {
            opacity: 0.6;
          }
        }

        &-next {
          align-items: flex-start;
          margin-top: -50px !important;
        }

        &-prev {
          align-items: flex-end;
          margin-bottom: -50px !important;
        }
      }

      .swiper-slide-active {
        height: auto !important;
        z-index: 2;

        .banner {
          height: auto;
          width: 100%;
          opacity: 1;
        }
      }
    }
  }

  .transform-up {
    transition: transform .3s;
  }
</style>

